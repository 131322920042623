import React from "react";
import styled from "styled-components";

const Button = styled.button`
  font-family: Nunito-SemiBold;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "#145388"};
  border-color: ${(props) =>
    props.borderColor
      ? props.borderColor
      : props?.backgroundColor || "#145388"};
  font-size: 14px;
  //padding: 0.2rem 1rem;
  color: ${(props) => (props.color ? props.color : "white")};
  &:hover {
    ${(props) => (props.hoverEffect ? props.hoverEffect : "color: white;")}
  }
`;

export default function Index({
  className,
  backgroundColor,
  color,
  children,
  hoverEffect,
  ...props
}) {
  return (
    <>
      <Button
        backgroundColor={backgroundColor}
        color={color}
        hoverEffect={hoverEffect}
        className={`btn text-uppercase px-3 py-1 rounded-pill ${className}`}
        {...props}
      >
        {children}
      </Button>
    </>
  );
}
