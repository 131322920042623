import React from "react";
import { Route, useRouteMatch } from "react-router-dom";

const Login = React.lazy(() => import("../screens/Auth/Login"));
const ForgotPassword = React.lazy(() =>
  import("../screens/Auth/ForgotPassword")
);
const ResetPassword = React.lazy(() => import("../screens/Auth/ResetPassword"));
const TemporaryPassword = React.lazy(() =>
  import("../screens/Auth/TemporaryPassword")
);

export default function AuthRoutes() {
  let { path } = useRouteMatch();
  return (
    <>
      <Route path={`${path}/login`} component={Login} />
      {/* temporary */}
      <Route path={`${path}/set-password`} component={TemporaryPassword} />
      {/* temporary */}
      <Route path={`${path}/forgot-password`} component={ForgotPassword} />
      <Route path={`${path}/reset-password/:token`} component={ResetPassword} />
    </>
  );
}
