import { createSlice } from "@reduxjs/toolkit";

// Slice

const slice = createSlice({
  name: "loader",
  initialState: {
    isloading: false,
  },
  reducers: {
    toggleLoading: (state, action) => {
      state.isloading = action.payload || !state.isloading;
    },
  },
});

export default slice.reducer;

export const { toggleLoading } = slice.actions;
