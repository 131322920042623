import React from "react";
import { Route, Switch, Redirect } from "react-router";
import AuthRoutes from "./AuthRoutes";
import TopBarProgress from "../components/TopBarProgress";
import useAuth from "../Hooks/useAuthHook";
import { useLocation } from "react-router-dom";
import { useMutation } from "react-query";
import { updateUserUrlLocation } from "../api/api";

const Main = React.lazy(() => import("../screens/Main"));

export var getAccessToken;

export default function Index() {
  const { user, isAuthenticated } = useAuth();
  const location = useLocation();
  const { mutate: updateUserUrlLocationMutate } = useMutation(
    updateUserUrlLocation
  );
  // React.useEffect(() => {
  //   if (user) {
  //     updateUserUrlLocationMutate({ url: window.location.href, user });
  //   }
  // }, [location]);
  return (
    <>
      <React.Suspense fallback={<TopBarProgress />}>
        {isAuthenticated ? (
          <>
            <Switch>
              <Route path="" component={Main}></Route>
              {/* <Route render={() => <Redirect to="/"></Redirect>} /> */}
            </Switch>
          </>
        ) : (
          <>
            <Switch>
              <Route path="/auth">
                <AuthRoutes />
              </Route>
              <Route render={() => <Redirect to="/auth/login"></Redirect>} />
            </Switch>
          </>
        )}
      </React.Suspense>
    </>
  );
}
