import { createSlice } from "@reduxjs/toolkit";

// Slice

const slice = createSlice({
  name: "notifications",
  initialState: {
    notification: [],
  },
  reducers: {
    setNotification: (state, action) => {
      state.notification = action.payload;
    },
    appendNewNotification: (state, action) => {
      state.notification = [action.payload, ...state.notification];
    },
  },
});

export default slice.reducer;

export const { setNotification, appendNewNotification } = slice.actions;
