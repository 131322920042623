import { createSlice } from "@reduxjs/toolkit";

// Slice

const slice = createSlice({
  name: "loader",
  initialState: {
    agencySearch: "",
    companySearch: "",
    contactSearch: "",
    licenseSearch: "",
    userSearch: "",
    userRoleSearch: "",
    userPermissionSearch: "",
    clientBondSearch: "",
    clientBondByStateSearch: "",
    clientByStateSearch: "",
    clientLicenseSearch: "",
    clientLoginSearch: "",
    clientMailingListSearch: "",
    clientServiceSearch: "",
    specialistSearch: "",
    stateBulkLoginSearch: "",
    stateLoginSearch: "",
  },
  reducers: {
    toggleAgencyValue: (state, action) => {
      state.agencySearch = action.payload;
    },
    toggleCompanyValue: (state, action) => {
      state.companySearch = action.payload;
    },
    toggleContactValue: (state, action) => {
      state.contactSearch = action.payload;
    },
    toggleLicenseValue: (state, action) => {
      state.licenseSearch = action.payload;
    },
    toggleUserValue: (state, action) => {
      state.userSearch = action.payload;
    },
    toggleUserRoleValue: (state, action) => {
      state.userRoleSearch = action.payload;
    },
    toggleUserPermissionValue: (state, action) => {
      state.userPermissionSearch = action.payload;
    },
    toggleClientBondValue: (state, action) => {
      state.clientBondSearch = action.payload;
    },
    toggleClientBondByStateValue: (state, action) => {
      state.clientBondByStateSearch = action.payload;
    },
    toggleClientByStateValue: (state, action) => {
      state.clientByStateSearch = action.payload;
    },
    toggleClientLicenseValue: (state, action) => {
      state.clientLicenseSearch = action.payload;
    },
    toggleClientLoginValue: (state, action) => {
      state.clientLoginSearch = action.payload;
    },
    toggleClientMailingListValue: (state, action) => {
      state.clientMailingListSearch = action.payload;
    },
    toggleClientServiceValue: (state, action) => {
      state.clientServiceSearch = action.payload;
    },
    toggleSpecialistValue: (state, action) => {
      state.specialistSearch = action.payload;
    },
    toggleStateBulkLoginValue: (state, action) => {
      state.stateBulkLoginSearch = action.payload;
    },
    toggleStateLoginValue: (state, action) => {
      state.stateLoginSearch = action.payload;
    },
  },
});

export default slice.reducer;

export const {
  toggleAgencyValue,
  toggleCompanyValue,
  toggleContactValue,
  toggleLicenseValue,
  toggleUserValue,
  toggleUserPermissionValue,
  toggleUserRoleValue,
  toggleClientBondValue,
  toggleClientBondByStateValue,
  toggleClientByStateValue,
  toggleClientLicenseValue,
  toggleClientLoginValue,
  toggleClientMailingListValue,
  toggleClientServiceValue,
  toggleSpecialistValue,
  toggleStateBulkLoginValue,
  toggleStateLoginValue,
} = slice.actions;
