import { createSlice } from "@reduxjs/toolkit";

// Slice

const slice = createSlice({
  name: "approvals",
  initialState: {
    approval: [],
  },
  reducers: {
    setApproval: (state, action) => {
      state.approval = [...action.payload];
    },
    appendNewApproval: (state, action) => {
      state.approval = [action.payload, ...state.approval];
    },
  },
});

export default slice.reducer;

export const { setApproval, appendNewApproval } = slice.actions;
