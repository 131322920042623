import React from "react";
import styled from "styled-components";

export const Loader = styled.div`
  display: inline-block;
  width: 40px;
  height: 40px;
  border: 4px solid rgba(100, 100, 100, 0.2);
  border-radius: 50%;
  border-top-color: #145388;
  z-index: 11111;

  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  left: calc(50% - 15px);
  top: calc(50% - 15px);
  position: fixed;
  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }

  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
`;
const BackDrop = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  background-color: #d9d9d9;
  opacity: 0.5;
  z-index: 1112;
`;
export default function Spinner() {
  return (
    <>
      <BackDrop></BackDrop>
      <Loader className="loading"></Loader>
    </>
  );
}
