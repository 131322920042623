import axios from "axios";
import { toast } from "react-toastify";

const BASE_URL = `/api/v1`;

const instance = axios.create({
  baseURL: BASE_URL,

  headers: {
    "Content-Type": "application/json",
    "cache-control": "no-cache",
  },
});
instance.defaults.withCredentials = true;

instance.interceptors.request.use(
  async (config) => {
    return config;
  },
  (error) => {
    return error;
  }
);

instance.interceptors.response.use(
  (response) => {
    if (response.data.message) {
      toast.dismiss();
      toast.success(response.data.message);
    }
    return response.data;
  },
  (error) => {
    toast.dismiss();
    if (error.response?.status === 401) {
      localStorage.removeItem("access_token");
      window.location.reload();
    } else if (error.response?.data?.error_description) {
      toast.error(error.response.data.error_description, {
        toastId: error.response.data.error_description,
      });
    } else if (error.response?.data?.message) {
      toast.error(error.response?.data?.message, {
        toastId: error.response?.data?.message,
      });
    } else if (error.response?.data?.error) {
      toast.error(error.response.data.error, {
        toastId: error.response.data.error,
      });
    } else if (error.response?.data) {
      toast.error(error.response?.data, {
        toastId: error.response?.data,
      });
    } else if (error.message) {
      toast.error(error.message, {
        toastId: error.message,
      });
    }
    throw error;
  }
);

export default instance;
