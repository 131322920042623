import React from "react";
import { useIsMutating } from "react-query";
import Loader from "../components/TopBarProgress/Spinner";

export default function LoadingIndicatorProvider({ children }) {
  const isMutating = useIsMutating();
  return (
    <>
      {isMutating > 0 && <Loader />}
      {children}
    </>
  );
}
